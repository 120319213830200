@import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");
@font-face {
    font-family: "Bebas";
    src: local("Bebas"), url("../../fonts/bebas.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "Jannat";
    src: local("Jannat"), url("../../fonts/jannatbold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "rosemery";
    src: local("rosemery"), url("../../fonts/rosemery.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "reey";
    src: local("reey"), url("../../fonts/reeyregular.otf") format("truetype");
    font-weight: bold;
}

.App {
    font-family: sans-serif;
    text-align: center;
}

body {
    background-color: black;
}

.chapter_title {
    padding: 10px;
    font-family: "Bebas";
    font-size: 1.7em;
    margin: auto;
    width: 80%;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.centerImg {
    margin: auto;
    //width: 50%;
    padding: 10px;
}

.chapter_title_ar {
    padding: 10px;
    font-family: "Jannat";
    font-size: 1.5em;
    margin: auto;
    width: 80%;
    padding: 10px;
    text-align: center;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

// .limitDiv {
//   text-align: right;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: "----";
// }
.header_title {
    font-family: "Bebas";
    font-size: 1em;
    color: #ffb300;
    padding: 10px;
}

.author_title {
    padding: 65px;
    text-align: center;
    font-family: "reey";
    font-size: 2em;
    color: #fff;
}

.author_title_ar {
    padding: 30px;
    text-align: center;
    font-family: "rosemery";
    font-size: 3em;
    color: #fff;
}

.header_title_ar {
    font-family: "Jannat";
    font-size: 1em;
    color: #ffb300;
    padding: 10px;
}

.book_title {
    font-family: "Bebas";
    font-size: 2.5em;
}

.main_div {
    background-color: #000;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.playerContainer {
    //width: 50%;
    //padding: 5px;
    width: 90%;
    height: 100%;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: #919191;
}

.titleContainer {
    margin: auto;
    display: block;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
}

.navContainer {
    padding-top: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.durationBar {
    padding: 15px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    font-weight: bold;
}

.BarContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.player {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40vh;
    padding: 20px 0;
    .song {
        user-select: none;
        margin: 0 20px;
        .song__title {
            text-align: left;
            margin: 0;
            color: white;
            font-family: "Permanent Marker", cursive;
            font-weight: normal;
            font-size: 3.5em;
            &:hover {
                color: greenyellow;
            }
        }
        .song__artist {
            margin: 0;
            color: white;
            font-family: "Rock Salt", cursive;
            font-weight: normal;
            font-size: 1em;
            &:hover {
                color: greenyellow;
                cursor: pointer;
            }
        }
    }
    .controls {
        flex-grow: 1;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .player__button {
        width: fit-content;
        margin-bottom: 15px;
        background-color: transparent;
        border: none;
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
            svg {
                color: #c78e10;
            }
        }
        svg {
            font-size: 4em;
            color: white;
        }
    }
    .bar {
        user-select: none;
        width: 100%;
        display: flex;
        align-items: center;
        .bar__time {
            color: white;
            font-size: 16px;
        }
        .bar__progress {
            flex: 1;
            border-radius: 5px;
            // margin: 0 20px;
            height: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            .bar__progress__knob {
                position: relative;
                height: 16px;
                width: 16px;
                border: 1.5px solid white;
                border-radius: 50%;
                background-color: orange;
            }
        }
    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        .controls {
            width: 100%;
            margin-top: 20px;
        }
        .bar {
            width: 90%;
        }
    }
    @media screen and (max-width: 500px) {
        .song {
            .song__title {
                font-size: 2.5em;
            }
            .song__artist {
                font-size: 0.8em;
            }
        }
    }
}